<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['spotShapes'])">
      <single-picture-select
        title="label.hyperpigmentationSpotShapes"
        :value="spotShapes"
        :options="$options.spotShapesOptions"
        @input="onFieldChange('spotShapes', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap
      class="q-mt8 q-mt16-md"
      :error="getFieldsError(['firstSpotsOccurrence'])"
    >
      <multi-answer-question
        id="first-spots-occurrence"
        title="label.firstSpotsOccurrence"
        :value="firstSpotsOccurrence"
        :options="spotOccurrenceOptions"
        dysfunction="skin-pigmentation"
        :max-answers="3"
        @input="onFieldChange('firstSpotsOccurrence', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import SinglePictureSelect from '@/modules/questionnaire/new-design-components/questions/SinglePictureSelect';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import MultiAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/MultiAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import {
  FIRST_SPOTS_OCCURRENCE_OPTIONS,
  SPOT_SHAPE_OPTIONS
} from '@/modules/questionnaire/constants/steps/skinPigmentation';

export default {
  name: 'TypeTemplate',
  components: { QuestionWithErrorWrap, SinglePictureSelect, MultiAnswerQuestion },
  spotShapesOptions: SPOT_SHAPE_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    spotShapes: {
      type: String,
      default: ''
    },
    firstSpotsOccurrence: {
      type: Array,
      default: () => []
    },
    spotOccurrenceValues: {
      type: Array,
      required: true
    }
  },
  computed: {
    spotOccurrenceOptions() {
      return FIRST_SPOTS_OCCURRENCE_OPTIONS.filter(({ value }) =>
        this.spotOccurrenceValues.includes(value)
      );
    }
  }
};
</script>
